<template>
    <v-list-item v-if="3 <= Object.keys(content).length" class="d-block" :class="{is_me: content.senderId === user._id, is_system: content.contentType==4}">
        <div class="mb-2 meta">
            <v-avatar size="32">
                <img :src="'/api/images/'+contentAvatar" />
            </v-avatar>
            <span class="ml-2 subtitle-2">{{contentName}}</span>
            <span class="ml-4 caption">{{content.created_at | fromNow}}</span>
        </div>
        <p class="d-flex msg" :class="{'ml-10': content.contentType!==4}">
            <span v-if="content.contentType==1" class="message-outer body-1 font-weight-light">
                <span class="message-decoration"></span>
                <span class="message-wrapper" v-html="displayText"></span>
            </span>
            <v-img 
                v-if="content.contentType==2"
                max-width="150"
                max-height="150"
                contain
                :src="'/api/images/'+content.stamp"
            >
                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey"></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <v-img
                v-if="content.contentType==3"
                max-width="300"
                max-height="300"
                contain
                :src="'/api/images/'+content.image"
            >
                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey"></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <span v-if="content.contentType==4" class="message-outer body-1 font-weight-light">
                <span class="message-wrapper">{{content.message}}</span>
            </span>
            <span v-if="content.contentType!==4" class="d-flex flex-column justify-end ml-2">
                <span v-if="content.isHurry" class="red--text caption">急ぎ</span>
                <span
                    class="mt-auto grey--text caption"
                    v-show="isShowAlreadyRead"
                >既読{{alreadyRead}}</span>
            </span>
        </p>
    </v-list-item>
</template>

<script>
import { auth, watchMembers } from "@/store";
export default {
    props: {
        content: {
            type: Object,
            require: true
        },
        isGroup: {
            type: Boolean,
            require: true
        }
    },
    data: () => ({
        user: auth.user,
        members: [],
    }),
    async created () {
        this.members = await watchMembers();
    },
    computed: {
        isShowAlreadyRead() {
            return this.content.senderId === this.user._id && this.content.alreadyReadList.length > 1;
        },
        alreadyRead() {
            return this.isGroup ? this.content.alreadyReadList.length - 1 : "";
        },
        contentAvatar () {
            const mem = this.members.find(member => member.id == this.content.senderId);
            return mem ? mem.avatar : "69616d746865617661746172";
        },
        contentName () {
            const mem = this.members.find(member => member.id == this.content.senderId);
            return mem ? mem.name : "";
        },
        displayText: function() {
            var retText = this.content.message;
            retText = retText.replace(/(https?:\/\/[^\s]*)/g, "<a class='link_str' href='$1' target='_blank'>$1</a>");
            return retText;
        },
    }
};
</script>

<style>
.message-outer {
    display: inline-block;
    position: relative;
    white-space: pre-line;
}
.message-wrapper {
    background-color: #ff9900;
    border-radius: 13px;
    display: inline-block;
    padding: 5px 10px;
    z-index: 3;
    position: relative;
    word-wrap: break-word;
    max-width: 300px;
}
.message-decoration {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: -10px;
    margin-top: -10px;
    position: absolute;
    z-index: 1;
    background: radial-gradient(circle at 50% 0%, transparent 65%, #ff9900 65%);
}
.is_me .meta,
.is_system .meta {
    display: none !important;
}
.is_me .msg {
    flex-direction: row-reverse;
}
.is_me .msg .message-outer {
    display: flex;
    flex-direction: row-reverse;
}
.is_me .msg .message-decoration {
    margin-right: -10px;
}

.is_system .msg .message-outer {
    margin: auto;
}
.is_system .msg .message-outer .message-wrapper {
    background-color: #eeeeee;
}
.link_str {
    color: #0033ff !important;
}
</style>