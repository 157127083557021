var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.timelinesWithName,"search":_vm.search,"multi-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("みんなの掲示板")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"検索...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('MTimelineEditor',{ref:"editor"})],1)]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(_vm._s(item.title))])]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(_vm._s(item.text))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("fromNow")(item.created_at)))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.controlEditor('editItem',item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.controlEditor('deleteItem',item)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_vm._v("データが存在しません。")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }