<template>
    <v-autocomplete
        :readonly="readonly"
        :label="label"
        v-model="selected"
        :items="companies"
        item-text="name"
        item-value="id"
        :rules="rules"
    ></v-autocomplete>
</template>

<script>
import { watchCompanies } from "@/store";
export default {
    props: {
        readonly: Boolean,
        label: String,
        value: String,
        rules: Array
    },
    data: () => ({
        companies: []
    }),
    async created() {
        this.companies = await watchCompanies();
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(new_value) {
                this.$emit("input", new_value);
            }
        }
    }
};
</script>

<style>
</style>