<template>
  <div class="fill-height d-flex flex-column">
    <v-toolbar dense flat class="flex-grow-0 flex-shrink-0">
      <v-btn
        :to="{ name: 'chat' }"
        exact
        text
        icon
        :class="{ 'd-none': $vuetify.breakpoint.lgAndUp }"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ room.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="room.isGroup">
        <template v-slot:activator="{ on }">
          <v-btn text icon v-on="on">
            <v-icon>mdi-settings-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="showMembers()">
            <v-list-item-title>メンバー</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-show="user._id == room.managerId"
            link
            @click="controlEditor('editItem', room)"
          >
            <v-list-item-title>編集</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="controlEditor('exitRoom', room)">
            <v-list-item-title>退出</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-show="user._id == room.managerId"
            link
            @click="controlEditor('deleteItem', room)"
          >
            <v-list-item-title>削除</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <MRoomEditor ref="editor"></MRoomEditor>
    </v-toolbar>
    <v-divider></v-divider>
    <v-list
      ref="contents"
      color="transparent"
      height="0"
      flat
      class="overflow-y-auto flex-grow-1 flex-shrink-1"
    >
      <message
        v-for="content in contents"
        :key="content._id"
        :content="content"
        :isGroup="room.isGroup"
      ></message>
    </v-list>
    <div class="d-flex flex-column flex-grow-0 flex-shrink-0">
      <v-textarea
        v-model="message"
        append-icon="mdi-face"
        append-outer-icon="mdi-send"
        :placeholder="placeholder"
        @focus="isStampMenuDisplayed = false"
        @click:append="isStampMenuDisplayed = !isStampMenuDisplayed"
        @click:append-outer="sendMessage"
        auto-grow
        hide-details
        outlined
        dence
        rows="1"
        class="mb-2 mx-4"
      >
        <template v-slot:prepend>
          <v-icon
            @click="isHurry = !isHurry"
            :color="isHurry ? 'red' : ''"
            class="mr-2"
            >mdi-email-alert</v-icon
          >
          <v-icon @click="choseImage">mdi-camera-image</v-icon>
          <input
            v-show="false"
            @change="onFileChange"
            ref="input"
            type="file"
            multiple
            accept="image/*"
          />
        </template>
      </v-textarea>
      <v-card v-show="isStampMenuDisplayed" class="flex-grow-0 flex-shrink-0">
        <v-tabs v-model="tab">
          <v-tab v-for="stamp in stamps" :key="stamp._id">
            <img
              height="40"
              width="40"
              :src="'/api/images/' + stamp.tabImage"
            />
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="stamp in stamps" :key="stamp._id">
            <v-card
              class="d-flex flex-wrap"
              flat
              height="220"
              style="overflow-y:scroll"
            >
              <v-img
                @click="sendStamp(name)"
                class="flex-grow-0 flex-shrink-0 ma-1"
                v-for="name in stamp.stamps"
                :key="name"
                height="65"
                width="65"
                :src="'/api/images/' + name"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <v-simple-table height="250px" v-if="is_show_members">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              メンバー
            </th>
            <th class="text-right">
              <v-btn class="mx-2" fab dark small color="primary" @click="showMembers()">
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in roomMembers" :key="item.id">
            <td><img :src="'/api/images/' + item.avatar" width="30px" /></td>
            <td>{{ item.name }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { auth, watchContents, watchStamps, watchMembers, axios } from '@/store';
import Message from '@/components/chat/Message';
import MRoomEditor from '@/components/MRoomEditor';
export default {
  props: {
    room: {
      type: Object,
      require: true,
    },
  },
  components: {
    Message,
    MRoomEditor,
  },
  data: () => ({
    is_show_members: false,
    tab: null,
    isHurry: false,
    isStampMenuDisplayed: false,
    user: auth.user,
    placeholder: 'メッセージを入力してください',
    message: '',
    contents: [],
    stamps: [],
    members: [],
  }),
  async created() {
    axios.put(`/rooms/${this.room.id}/read`);
    this.stamps = await watchStamps();
    this.contents = await watchContents(this.room.id);
    this.members = await watchMembers();
  },
  updated() {
    this.scrollToTop();
  },
  watch: {
    contents: {
      handler() {
        axios.put(`/rooms/${this.room.id}/read`);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    roomMembers () {
      return this.members.filter(member => this.room.members.includes(member.id));
    }
  },
  methods: {
    showMembers() {
      this.is_show_members = !this.is_show_members;
    },
    controlEditor(action, ...value) {
      this.$refs.editor[action](...value);
    },
    isHurryConfirm() {
      return this.isHurry && !confirm( this.room.isGroup ?
          'グループ内の会員全てにメールで通知されます。\nよろしいですか？' :
          'チャット相手にメールで通知されます。\nよろしいですか？'
        );
    },
    async sendMessage() {
      if (!this.message) return;
      if ( this.isHurryConfirm() ) return;
      
      axios.post('rooms/' + this.room.id + '/message', {
        isHurry: this.isHurry,
        message: this.message,
      });
      this.isHurry = false;
      this.message = '';
    },
    async sendStamp(name) {
      if ( this.isHurryConfirm() ) return;

      await axios.post('rooms/' + this.room.id + '/stamp', {
        isHurry: this.isHurry,
        stamp: name,
      });
      this.isHurry = false;
    },
    choseImage() {
      this.$refs.input.value = null;
      this.$refs.input.click();
    },
    async sendImage(image) {
      if ( this.isHurryConfirm() ) return;

      await axios.post('rooms/' + this.room.id + '/image', {
        isHurry: this.isHurry,
        image: image,
      });
      this.isHurry = false;
    },
    async onFileChange(event) {
      for (const file of event.target.files) {
        const formData = new FormData();
        formData.append('file', file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axios.post('/images', formData, config).then(({ data }) => {
          this.sendImage(data.name);
        });
      }
    },
    //コンテンツ一覧の一番下までスクロール
    scrollToTop() {
      this.$refs.contents.$el.scrollTop = this.$refs.contents.$el.scrollHeight;
    },
  },
};
</script>
