<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-row class="mt-4" justify="center">
                    <MAvatarEditor v-model="editedItem.avatar" :isAuto="true"></MAvatarEditor>
                </v-row>
                <v-row class="mt-3 display-1" justify="center">{{editedItem.name}}</v-row>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <v-row>
                    <v-col cols="12">
                        <div class="headline ml-4">情報</div>
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                        <div class="item">
                            <span class="sup">ふりがな</span>
                            <span class="text">{{ editedItem.ruby }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="item">
                            <span class="sup">電話番号</span>
                            <span class="text">{{ editedItem.tel }}</span>    
                        </div>
                    </v-col>

                    <v-col cols="12">
                        <div class="item">
                            <span class="sup">メールアドレス</span>
                            <span class="text">{{ editedItem.mail }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <v-row>
                    <v-col cols="12">
                        <span class="headline ml-4">秘書</span>
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                        <div class="item">
                            <span class="sup">氏名</span>
                            <span class="text">{{ editedItem.secretaryName }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="item">
                            <span class="sup">メールアドレス</span>
                            <span class="text">{{ editedItem.secretaryMail }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <v-row>
                    <v-col cols="12">
                        <span class="headline ml-4">所属</span>
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                        <div class="item">
                            <span class="sup">部門</span>
                            <span class="text">{{ editedItem.department }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="item">
                            <span class="sup">会社名</span>
                            <span class="text">{{ companyName }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="item">
                            <span class="sup">役職</span>
                            <span class="text">{{ editedItem.post }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <v-col cols="12">
                <v-row justify="center">
                    <v-btn @click="save" color="primary">保存する</v-btn>
                </v-row>
            </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
import { auth, watchCompanies } from "@/store";
import MAvatarEditor from "@/components/MAvatarEditor";
export default {
    components: {
        MAvatarEditor
    },
    data: () => ({
        user: auth.user,
        companies: [],
        editedItem: {
            avatar: "",
            name: "",
            ruby: "",
            tel: "",
            mail: "",
            companyId: "",
            post: "",
            department: "",
            secretaryName: "",
            secretaryMail: ""
        }
    }),
    async created() {
        this.editedItem = Object.assign({}, this.user);
        this.companies = await watchCompanies();
    },
    computed: {
        companyName () {
            const com = this.companies.find(ele => ele.id.toString() == this.editedItem.companyId);
            return com ? com.name : "";
        }
    },
    methods: {
        // save() {
        //     confirm("現在の情報に更新しますか？") &&
        //         axios.post("me", this.editedItem);
        // }
    }
};
</script>

<style scoped>
.item {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    border-bottom: 1px solid #ccc;
}

.sup {
    font-size: 50%;
    color: rgba(0,0,0,.6);
}
</style>