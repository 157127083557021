<template>
    <v-dialog v-model="dialog" :fullscreen="!$vuetify.breakpoint.lgAndUp" max-width="510px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form v-model="valid" ref="form">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editedItem.title"
                                    label="タイトル"
                                    counter="32"
                                    :rules="rules.title"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="editedItem.text"
                                    label="本文"
                                    no-resize
                                    counter="1000"
                                    :rules="rules.text"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <MImageList v-model="editedItem.images"></MImageList>
                                <MImageUploader v-model="editedItem.images"></MImageUploader>
                            </v-col>
                            <v-col cols="12">
                                <v-switch v-model="editedItem.isInvitation" class="mx-2" label="会員を招待"></v-switch>
                            </v-col>
                            <v-col cols="12" v-show="editedItem.isInvitation">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="editedItem.deadline_at"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, deadline_at }">
                                        <v-text-field
                                            v-bind="deadline_at"
                                            v-model="editedItem.deadline_at"
                                            label="回答締切日"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            :rules="rules.deadline_at"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="editedItem.deadline_at"
                                        no-title
                                        scrollable
                                        :allowed-dates="allowedDates"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">閉じる</v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(editedItem.deadline_at)"
                                        >決定する</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" v-show="editedItem.isInvitation">
                                <v-menu
                                    ref="menu_time"
                                    v-model="menu_time"
                                    :close-on-content-click="false"
                                    :return-value.sync="editedItem.deadline_time"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, deadline_time }">
                                        <v-text-field
                                            v-bind="deadline_time"
                                            v-model="editedItem.deadline_time"
                                            label="回答締切時間"
                                            prepend-icon="mdi-clock"
                                            readonly
                                            :rules="rules.deadline_time"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-model="editedItem.deadline_time"
                                        format="ampm"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu_time = false">閉じる</v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu_time.save(editedItem.deadline_time)"
                                        >決定する</v-btn>
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" v-show="editedItem.isInvitation">
                                <VSelectMembers
                                    label="招待会員選択"
                                    v-model="editedItem.members"
                                    :rules="rules.members"
                                ></VSelectMembers>
                            </v-col>
                            <v-col cols="12" v-show="this.editedId && editedItem.isInvitation">
                                <v-data-table
                                    :headers="headers"
                                    :items="editedItem.members"
                                    :search="search"
                                    :mobile-breakpoint="0"
                                    dense
                                >
                                    <template v-slot:item.name="{item}">
                                        <span>{{members.find(mem => mem.id.toString() == item) ? members.find(mem => mem.id.toString() == item).name : ""}}</span>
                                    </template>
                                    <template v-slot:item.status="{item}">
                                        <span class="d-inline-block text-truncate" style="max-width:200px;">{{ editInvitationStatusTable && ["未回答","参加","不参加"][editInvitationStatusTable[item]] || "未回答" }}</span>
                                    </template>
                                    <template v-slot:item.action="{item}">
                                        <span v-show="memberExists(item)">
                                            <v-btn color="accent" small @click="participation(item, 1)">参加</v-btn>
                                            <v-btn color="accent" small @click="participation(item, 2)">不参加</v-btn>
                                        </span>
                                    </template>
                                </v-data-table>
                                <span class="participationAttention">参加・不参加の変更は取り消せません</span>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="accent" text @click="close">取り消し</v-btn>
                <v-btn
                    :disabled="!valid"
                    color="accent"
                    text
                    @click="save"
                >保存する</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { axios, watchMembers, watchInvitationsAdmin } from "@/store";
import VSelectMembers from "@/components/VSelectMembers";
import MImageList from "@/components/MImageList";
import MImageUploader from "@/components/MImageUploader";
export default {
    components: {
        VSelectMembers,
        MImageList,
        MImageUploader
    },
    data: () => ({
        search: "",
        dialog: false,
        menu: false,
        valid: false,
        editedId: null,
        editedItem: {
            title: "",
            text: "",
            images: [],
            members: [],
            deadline_at: "",
            deadline_time: "",
            isInvitation: false,
            statusTable: {}
        },
        defaultItem: {
            title: "",
            text: "",
            images: [],
            members: [],
            deadline_at: "",
            deadline_time: "",
            isInvitation: false,
            statusTable: {}
        },
        headers: [
            {
                text: "会員名",
                align: "left",
                sortable: false,
                value: "name"
            },
            {
                text: "参加",
                align: "right",
                sortable: true,
                value: "status"
            },
            {
                text: "",
                align: "right",
                sortable: false,
                value: "action"
            }
        ],
        members: [],
        invitations: []
    }),
    async created () {
        this.members = await watchMembers();
        this.invitations = await watchInvitationsAdmin();
    },
    computed: {
        rules() {
            const self = this;
            return {
                title: [
                    v => !!v || "必須項目です。",
                    v =>
                        (v && 1 <= v.length && v.length <= 32) ||
                        "1文字以上32文字以下のみ"
                ],
                text: [
                    v => !!v || "必須項目です。",
                    v =>
                        (v && 1 <= v.length && v.length <= 1000) ||
                        "1文字以上1000文字以下のみ"
                ],
                deadline_at: [v => !self.editedItem.isInvitation || !!v || "必須項目です。" ],
                //deadline_time: [v => !self.editedItem.isInvitation || !!v || "必須項目です。" ],
                members: [v => !self.editedItem.isInvitation || (v && 1 <= v.length) || "必須項目です。1人以上選択してください。"]
            }
        },
        formTitle() {
            return this.editedId ? "会のご案内編集" : "会のご案内作成";
        },
        editInvitationStatusTable () {
            if (!this.editedId) return null;
            const i = this.invitations.find(invitation => invitation.id == this.editedId);
            return i ? i.statusTable : null;
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        }
    },

    methods: {
        participation (memberId, status) {
            axios.put("invitations/" + this.editedId + "/status/" + memberId, { status });
        },
        memberExists (memberId) {
            if (!this.editedId) return false;
            const i = this.invitations.find(invitation => invitation.id == this.editedId);
            if (!i) return false;
            return i.members.some(iv => iv === memberId);
        },
        allowedDates(day) {
            return new Date().getTime() <= new Date(day).getTime();
        },
        createItem() {
            this.dialog = true;
        },
        editItem(invitation) {
            this.editedId = invitation.id;
            this.editedItem = Object.assign({}, invitation);
            this.dialog = true;
        },

        deleteItem(invitation) {
            confirm("この会のご案内を削除してもよろしいですか？") &&
                axios.delete("invitations/" + invitation.id);
        },
        close() {
            this.dialog = false;
            this.$refs.form.resetValidation();
            this.editedId = null;
            this.editedItem = Object.assign({}, this.defaultItem);
        },
        async save() {
            const d = new Date(this.editedItem.deadline_at);
            this.editedItem.deadline_at = `${d.getFullYear()}-${(d.getMonth()+1)<10?"0":""}${(d.getMonth()+1)}-${d.getDate()<10?"0":""}${d.getDate()}`;
            if (this.editedId) {
                await axios.post("invitations/" + this.editedId, this.editedItem);
            } else {
                await axios.post("invitations", this.editedItem);
            }
            console.log(this.editedItem);
            this.close();
        }
    }
};
</script>

<style>
.participationAttention {
    color: red;
}
</style>