<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6" lg="4">
                <v-row>
                    <v-col cols="12">
                        <div class="headline ml-4">設定</div>
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            v-model="editedItem.notificationInterval"
                            label="通知"
                            :items="items"
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row justify="center">
                    <v-btn @click="save" color="primary">保存する</v-btn>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { auth, axios } from "@/store";
export default {
    data: () => ({
        user: auth.user,
        items: [
            {
                text: "通知しない",
                value: 0
            },
            {
                text: "30分おき",
                value: 0.5
            },
            {
                text: "1時間おき",
                value: 1
            },
            {
                text: "2時間おき",
                value: 2
            },
            {
                text: "3時間おき",
                value: 3
            },
            {
                text: "4時間おき",
                value: 4
            },
            {
                text: "12時間おき",
                value: 12
            },
            {
                text: "24時間おき",
                value: 24
            }
        ],
        editedItem: {
            notificationInterval: ""
        }
    }),
    created() {
        this.editedItem = Object.assign({}, this.user);
    },
    methods: {
        save() {
            confirm("現在の情報に更新しますか？") &&
                axios.put("members/global-settings", this.editedItem);
        }
    }
};
</script>

<style>
</style>