<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <div class="headline ml-4">会のご案内</div>
                <v-divider></v-divider>
            </v-col>
            <v-col v-if="invitations.length == 0" cols="12">投稿はありません。</v-col>
            <v-col v-else cols="12" md="6" v-for="invitation in displayInvitations" :key="invitation.id">
                <invitation-item class="mx-auto mb-4" :invitation="invitation"></invitation-item>
            </v-col>
            <v-col cols="12">
                <v-pagination v-model="page" :length="pageLength"></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { watchInvitations } from "@/store";
import InvitationItem from "./InvitationItem";
export default {
    components: {
        InvitationItem
    },
    data: () => ({
        page: 1,
        show_length: 10,
        invitations: []
    }),
    async created() {
        this.invitations = await watchInvitations();
    },
    computed: {
        pageLength() {
            return Math.ceil(this.invitations.length / this.show_length);
        },
        displayInvitations() {
            var sortdata = this.invitations.slice().reverse();
            return sortdata.slice( (this.page-1) * this.show_length, this.page * this.show_length);
        }
    }
};
</script>
