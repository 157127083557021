import io from "socket.io-client";
export const socket = io();

import axiosBase from "axios";
export const axios = axiosBase.create({
    baseURL: `${location.origin}/api`,
    headers: {
        "Content-Type": "application/json"
    },
    responseType: "json"
});

let cache = {}

export const auth = {
    async login(name, password) {
        return await axios.post("/login", { name, password });
    },
    async logout() {
        await axios.post("/logout");
        this.isInitialized = false;
        socket.emit("leave", [this.user._id]);
        this.user = {};
        for (let property in cache) {
            cache[property].dataArray.splice(0, cache[property].dataArray.length);
        }
        cache = {};
        return
    },
    isInitialized: false,
    async init() {
        return axios.get("me").then(({ data }) => {
            this.isInitialized = true;
            this.user = Object.assign({}, this.user, data);
            socket.emit("join", [this.user._id]);
        }).catch(() => {

        });
    },
    user: {}

}
export async function watch(initUrl, option = {}, initFunc = 0) {
    if (cache[initUrl] && cache[initUrl].isInitialized) {
        // target.splice(0, 0, ...cache[initUrl].dataArray);
        // cache[initUrl].dataArray = target;
        return cache[initUrl].dataArray;
    }

    cache[initUrl] = Object.assign(
        cache[initUrl] || {},
        {
            option: Object.assign({
                event: initUrl,
                insert: function (array, change) {
                    const { documentId, document } = change;
                    Object.defineProperty(document, "id", {
                        value: documentId,
                        writable: false
                    });
                    if (array.some(instance => instance.id == documentId)) {
                        return;
                    }
                    array.splice(0, 0, document);
                },
                update: function (array, change) {
                    const { documentId, document } = change;
                    Object.defineProperty(document, "id", {
                        value: documentId,
                        writable: false
                    });
                    const index = array.findIndex(
                        instance => instance.id == documentId
                    );
                    if (index != -1) {
                        array.splice(index, 1, document);
                    }
                },
                delete: function (array, change) {
                    const { documentId } = change;
                    const index = array.findIndex(
                        instance => instance.id == documentId
                    );
                    if (index != -1) {
                        array.splice(index, 1);
                    }
                },
                upsert: function (array, change) {
                    const { documentId, document } = change;
                    Object.defineProperty(document, "id", {
                        value: documentId,
                        writable: false
                    });
                    const index = array.findIndex(
                        instance => instance.id == documentId
                    );
                    if (index != -1) {
                        array.splice(index, 1, document);
                    } else {
                        array.splice(0, 0, document);
                    }
                }
            }, option),
            dataArray: []
        })
    const { data } = await axios.get(initUrl);
    //console.log("get init data", data);
    data.forEach(instance => {
        Object.defineProperty(instance, "id", {
            value: instance._id,
            writable: false
        });
        delete instance._id;
    });
    if (typeof initFunc === "function") {
        initFunc(data);
    }
    // target.splice(0, 0, ...data);
    cache[initUrl].dataArray.splice(0, cache[initUrl].dataArray.length, ...data);
    socket.on(cache[initUrl].option.event, change => {
        //console.log(cache,name,change);
        const operationType = change.operationType;
        if (typeof cache[initUrl].option[operationType] === "function") {
            cache[initUrl].option[operationType](cache[initUrl].dataArray, change);
        } else {
            console.error("登録されていないoperationType:", change.operationType);
        }
    });
    cache[initUrl].isInitialized = true;
    return cache[initUrl].dataArray;
}
export async function watchMembers () {
    return await watch("members", {
        update: function (array, change) {
            const { documentId, document } = change;
            Object.defineProperty(document, "id", {
                value: documentId,
                writable: false
            });
            const index = array.findIndex(
                instance => instance.id == documentId
            );
            if (index != -1) {
                array.splice(index, 1, document);
            }
            if (documentId == auth.user._id) {
                auth.user = Object.assign({}, auth.user, document);
            }
        },
    });
}
export async function watchCompanies () {
    return await watch("companies");
}
export async function watchRooms () {
    return await watch("rooms", {
        insert(array, change) {
            const { documentId, document } = change;
            if (!document.members.some(val => val == auth.user._id)) {
                return;
            }
            socket.emit("join", [`rooms:${documentId}`]);
            Object.defineProperty(document, "id", {
                value: documentId,
                writable: false
            });
            if (array.some(instance => instance.id == documentId)) {
                return;
            }
            array.splice(0, 0, document);
        },
        upsert(array, change) {
            const { documentId, document } = change;
            Object.defineProperty(document, "id", {
                value: documentId,
                writable: false
            });
            const index = array.findIndex(
                instance => instance.id == documentId
            );
            if (index != -1) {
                array.splice(index, 1, document);
            } else {
                array.splice(0, 0, document);
                socket.emit("join", [`rooms:${documentId}`]);
            }
        },
        delete(array, change) {
            const { documentId } = change;
            const index = array.findIndex(
                instance => instance.id == documentId
            );
            if (index != -1) {
                socket.emit("leave", `rooms:${documentId}`);
                array.splice(index, 1);
            }
        }
    }, d => {
        let inRooms = [];
        d.forEach(dRoom => inRooms.push(`rooms:${dRoom.id}`));
        socket.emit("join", inRooms);
    });
}
export async function watchInvitations () {
    return await watch("invitations", {
        insert(array, change) {
            const { documentId, document } = change;
            if (document.isInvitation && document.members && !document.members.some(val => val == auth.user._id)) {
                return;
            }
            Object.defineProperty(document, "id", {
                value: documentId,
                writable: false
            });
            if (array.some(instance => instance.id == documentId)) {
                return;
            }
            array.splice(0, 0, document);
        }
    });
}
export async function watchInvitationsAdmin () {
    return await watch("/invitations/admin", { event: "invitations:admin" });
}
export async function watchStamps () {
    return await watch("stamps", {
        insert: function (array, change) {
            const { documentId, document } = change;
            Object.defineProperty(document, "id", {
                value: documentId,
                writable: false
            });
            if (!document.isAll && !document.members.some(mem => mem.toString() == auth.user._id)) {
                return;
            }
            if (array.some(instance => instance.id == documentId)) {
                return;
            }
            array.splice(0, 0, document);
        },
        update: function (array, change) {
            const { documentId, document } = change;
            Object.defineProperty(document, "id", {
                value: documentId,
                writable: false
            });
            const index = array.findIndex(
                instance => instance.id == documentId
            );
            if (index != -1) {
                if (!document.isAll && !document.members.some(mem => mem.toString() == auth.user._id)) {
                    array.splice(index, 1);
                } else {
                    array.splice(index, 1, document);
                }
            } else if (document.isAll || (!document.isAll && document.members.some(mem => mem.toString() == auth.user._id))) {
                array.splice(0, 0, document);
            }
        }
    });
}
export async function watchStampsAdmin () {
    return await watch("/stamps/admin", { event: "stamps" });
}
export async function watchContents (roomId) {
    return await watch(`/rooms/${roomId}/contents`, {
        event: `rooms:${roomId}:contents`,
        insert(array, change) {
            const { documentId, document } = change;
            Object.defineProperty(document, 'id', {
                value: documentId,
                writable: false,
            });
            if (array.some(instance => instance.id == documentId)) {
                return;
            }
            array.splice(array.length, 0, document);
        },
    });
}
export async function watchTimelines () {
    return await watch("timelines");
}
export async function watchMyTimelines () {
    return await watch("/me/timelines", {
        event: "timelines",
        insert(array, change) {
            const { documentId, document } = change;
            if (document.senderId != auth.user._id) {
                return;
            }
            Object.defineProperty(document, "id", {
                value: documentId,
                writable: false
            });
            if (array.some(instance => instance.id == documentId)) {
                return;
            }
            array.splice(0, 0, document);
        }
    });
}
export async function watchComments (timelineId) {
    return await watch(`/timelines/${timelineId}/comment`, {
        event: `timelines:${timelineId}:comment`,
        insert(array, change) {
            const { documentId, document } = change;

            Object.defineProperty(document, "id", {
                value: documentId,
                writable: false
            });
            if (array.some(instance => instance.id == documentId)) {
                return;
            }
            array.splice(array.length, 0, document);
        }
    });
}

window.cache = cache;
