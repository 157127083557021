<template>
    <v-btn color="primary" @click="onClick">
        画像のアップロード
        <input
            v-show="false"
            @change="onFileChange"
            ref="input"
            type="file"
            accept="image/*"
            multiple
        />
    </v-btn>
</template>

<script>
import { axios } from "@/store";
import imageCompression from 'browser-image-compression';
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        onClick() {
            this.$refs.input.value = null;
            this.$refs.input.click();
        },
        async onFileChange(event) {
            for (const file of event.target.files) {
                const formData = new FormData();

                const options = {
                    maxSizeMB: 0.7,
                    maxWidthOrHeight: 1280,
                    useWebWorker: true
                }
                const compressed_blob = await imageCompression(file, options);
                const compressed_file = new File([compressed_blob], file.name, { type: file.type });
                formData.append("file", compressed_file);
                const config = {
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                };
                axios.post("/images", formData, config).then(({ data }) => {
                    this.$emit("input", this.value.concat(data.name));
                });
            }
        }
    }
};
</script>

<style>
</style>