<template>
    <v-card>
        <v-list-item>
            <v-list-item-avatar>
                <img :src="'/api/images/'+timelineAvatar" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="headline">{{timeline.title}}</v-list-item-title>
                <v-list-item-subtitle>{{timelineName}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn color="accent" v-if="user._id === timeline.senderId" @click="controlEditor('editItem',timeline)">編集</v-btn>
            <MTimelineEditor ref="editor"></MTimelineEditor>
        </v-list-item>
        <v-sheet>
            <v-carousel show-arrows-on-hover v-if="timeline.images.length > 0">
                <a v-for="image in timeline.images" :key="image" v-bind:href="'/api/images/' + image" rel="lightbox">
                    <v-carousel-item
                        :key="image"
                        :src="'/api/images/'+image"
                        contain
                    ></v-carousel-item>
                </a>
            </v-carousel>
        </v-sheet>
        <v-card-text
            class="font-regular black--text"
            style="white-space: pre-line"
            v-html="displayText"
        ></v-card-text>
        <v-avatar class="mx-2" color="accent" size="27"
            v-if="is_show_newIcon">
            <span class="white--text subtitle-2">new</span>
        </v-avatar>
        <v-btn
            v-if="!is_comment_displyed && timeline.commentsCount > 0"
            @click="showComments"
            depressed
            text
        >コメント{{timeline.commentsCount}}件全てを表示する</v-btn>
        <v-list v-show="is_comment_displyed" dence>
            <v-list-item class="d-block" v-for="(comment,index) in commentsWithAvatarAndName" :key="index">
                <div class="mb-1">
                    <v-avatar size="30">
                        <img :src="'/api/images/'+comment.avatar" />
                    </v-avatar>
                    <span class="ml-2 caption">{{comment.name}}</span>
                </div>
                <p
                    class="d-flex flex-column ml-8 body-2 font-weight-light"
                    style="white-space: pre-line"
                >
                    {{comment.text}}
                    <span
                        class="grey--text caption"
                    >{{comment.created_at | fromNow}}</span>
                </p>
            </v-list-item>
        </v-list>
        <div class="mx-4 text-right caption grey--text">{{timeline.created_at | fromNow}}</div>
        <v-divider></v-divider>
        <v-card-actions>
            <v-text-field v-model="editedComment" :placeholder="placeholder"></v-text-field>
            <v-btn
                :disabled="editedComment.length == 0"
                text
                color="accent-4"
                @click="sendComment"
            >投稿する</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { watchComments, watchMembers, axios } from "@/store";
import MTimelineEditor from "@/components/MTimelineEditor";
export default {
    components: {
        MTimelineEditor
    },
    props: {
        timeline: {
            type: Object,
            require: true
        },
        user: {
            type: Object,
            require: true
        }
    },
    data: () => ({
        is_comment_displyed: false,
        placeholder: "コメントを入力してください",
        editedComment: "",
        comments: [],
        members: [],
    }),
    async created () {
        this.members = await watchMembers();
    },
    computed: {
        is_show_newIcon: function() {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return new Date(this.timeline.lcca) > date;
        },
        timelineAvatar () {
            const mem = this.members.find(member => member.id == this.timeline.senderId);
            return mem ? mem.avatar : "69616d746865617661746172";
        },
        timelineName () {
            const mem = this.members.find(member => member.id == this.timeline.senderId);
            return mem ? mem.name : "";
        },
        commentsWithAvatarAndName () {
            return this.comments.map(comment => {
                const mem = this.members.find(member => member.id == comment.senderId);
                comment.avatar = mem ? mem.avatar : "69616d746865617661746172";
                comment.name = mem ? mem.name : "";
                return comment;
            });
        },
        displayText: function() {
            var retText = this.timeline.text;
            retText = retText.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank'>$1</a>");
            return retText;
        },
    },
    methods: {
        showComments() {
            this.is_comment_displyed = true;
            this.aWatchComments();
        },
        async aWatchComments() {
            this.comments = await watchComments(this.timeline.id);
        },
        sendComment() {
            this.placeholder = "送信中...";
            axios
                .post("timelines/" + this.timeline.id + "/comment", {
                    text: this.editedComment
                })
                .then(() => {
                    this.placeholder = "コメントを入力してください";
                });
            this.editedComment = "";
        },
        controlEditor(action, ...value) {
            this.$refs.editor[action](...value);
        }
    }
};
</script>

<style>
</style>