<template>
    <v-container>
        <v-data-table :headers="headers" :items="timelinesWithName" :search="search" multi-sort class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>みんなの掲示板</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="検索..."
                        single-line
                        hide-details
                    ></v-text-field>
                    <MTimelineEditor ref="editor"></MTimelineEditor>
                </v-toolbar>
            </template>
            <template v-slot:item.title="{ item }">
                <span class="d-inline-block text-truncate" style="max-width:200px;">{{item.title}}</span>
            </template>
            <template v-slot:item.text="{ item }">
                <span class="d-inline-block text-truncate" style="max-width:200px;">{{item.text}}</span>
            </template>
            <template v-slot:item.created_at="{ item }">{{item.created_at | fromNow}}</template>
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="controlEditor('editItem',item)">mdi-pencil</v-icon>
                <v-icon small @click="controlEditor('deleteItem',item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>データが存在しません。</template>
        </v-data-table>
    </v-container>
</template>

<script>
import { watchTimelines, watchMembers } from "@/store";
import MTimelineEditor from "@/components/MTimelineEditor";
export default {
    components: {
        MTimelineEditor
    },
    data: () => ({
        search: "",
        headers: [
            {
                text: "投稿者",
                align: "left",
                sortable: false,
                value: "name"
            },
            {
                text: "タイトル",
                align: "left",
                sortable: false,
                value: "title"
            },
            {
                text: "本文頭",
                align: "left",
                sortable: false,
                value: "text"
            },
            {
                text: "作成日時",
                align: "right",
                sortable: true,
                value: "created_at"
            },
            { text: "", align: "right", sortable: false, value: "action" }
        ],
        timelines: [],
        members: [],
    }),
    async created() {
        this.timelines = await watchTimelines();
        this.members = await watchMembers();
    },
    computed: {
        timelinesWithName () {
            return this.timelines.map(timeline => {
                const mem = this.members.find(member => member.id == timeline.senderId);
                timeline.name = mem ? mem.name : "";
                return timeline;
            });
        }
    },
    methods: {
        controlEditor(action, ...value) {
            this.$refs.editor[action](...value);
        }
    }
};
</script>

<style>
</style>