<template>
    <v-card>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="headline">{{invitation.title}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-sheet v-if="invitation.images.length > 0">
            <v-carousel show-arrows-on-hover height="300">
                <a v-for="url in invitation.images" :key="url" v-bind:href="'/api/images/' + url" rel="lightbox">
                    <v-carousel-item
                        :key="url"
                        :src="'/api/images/' + url"
                        contain
                    ></v-carousel-item>
                </a>
            </v-carousel>
        </v-sheet>
        <v-card-text
            class="font-regular black--text"
            style="white-space: pre-line"
            v-html="displayText"
        ></v-card-text>
        <div v-show="invitation.isInvitation">
            <v-divider></v-divider>
            <v-card-title>
                招待者
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="検索..."
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="displayMembers"
                :search="search"
                :mobile-breakpoint="0"
                :sort-by="['ruby']"
                dense
            >
                <template v-slot:item.name="{item}">
                    <span>{{ item.name }}</span>
                </template>
                <template v-slot:item.ruby="{item}">
                    <span>{{ item.ruby }}</span>
                </template>
                <template v-slot:item.status="{item}">
                    <span>{{ invitation.statusTable && ["未回答","参加","不参加"][invitation.statusTable[item.id]] || "未回答" }}</span>
                </template>
            </v-data-table>
            <p class="text-center">回答締切期限 : {{deadline_at}} {{deadline_time}}</p>
            <v-card-actions v-if="!is_over_deadline" class="mt-12 d-flex justify-space-around">
                <v-btn-toggle>
                    <v-btn @click="setStatus(2)" outlined color="accent" class="participation_btn">不参加</v-btn>
                    <v-btn @click="setStatus(1)" outlined color="accent" class="participation_btn">参加</v-btn>
                </v-btn-toggle>
            </v-card-actions>
            <v-card-actions v-if="is_over_deadline" class="mt-12 d-flex justify-space-around">
                <div color="accent">回答期限が過ぎています</div>
            </v-card-actions>
            <div class="mx-4 text-right caption grey--text">投稿日時:{{invitation.created_at | fromNow}}</div>
        </div>
    </v-card>
</template>

<script>
import { axios, watchMembers } from "@/store";
export default {
    props: {
        invitation: {
            type: Object,
            require: true
        }
    },
    data: () => ({
        search: "",
        month: ['1','2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        headers: [
            {
                text: "会員名",
                align: "left",
                sortable: false,
                value: "name"
            },
            {
                text: "ふりがな",
                align: "left",
                sortable: true,
                value: "ruby"
            },
            {
                text: "参加",
                align: "right",
                sortable: true,
                value: "status"
            }
        ],
        members: []
    }),
    async created () {
        this.members = await watchMembers();
    },
    computed: {
        deadline_at: function() {
            const deadline = new Date(this.invitation.deadline_at);
            return `${deadline.getFullYear()}年${this.month[deadline.getMonth()]}月${deadline.getDate()}日`;
        },
        deadline_time: function() {
            var ret_time = "";
            if (typeof this.invitation.deadline_time !== 'undefined' && this.invitation.deadline_time != '') {
                const split_data = this.invitation.deadline_time.split(':');
                ret_time = split_data[0] + "時" + split_data[1] + "分";
            }
            return ret_time;
        },
        is_over_deadline: function () {
            const deadline = new Date(this.invitation.deadline_at);
            deadline.setDate( deadline.getDate() + 1);

            if (typeof this.invitation.deadline_time !== 'undefined' && this.invitation.deadline_time !== '') {
                const split_data = this.invitation.deadline_time.split(':');
                deadline.setHours(split_data[0]);
                deadline.setMinutes(split_data[1]);
            }

            // 回答期限が過ぎているか確認
            return Date.now() > deadline;
        },
        displayText: function() {
            var retText = this.invitation.text;
            retText = retText.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank'>$1</a>");
            return retText;
        },
        displayMembers: function() {
            let display = [];
            if (this.members.length != 0 && this.invitation.members.length != 0) {
                let disp_member = [];
                this.invitation.members.forEach(element => {
                    var find_member = this.members.find(function(mem){
                        return mem.id.toString() == element;
                    });
                    if (find_member) {
                        disp_member.push(find_member);
                    }
                });
                
                disp_member.forEach(element => {
                    var ans = this.invitation.statusTable && ["未回答","参加","不参加"][this.invitation.statusTable[element.id]] || "未回答";
                    element.status = ans;
                    if (element.name.indexOf(this.search) !== -1 || element.ruby.indexOf(this.search) !== -1 || ans.indexOf(this.search) !== -1) {
                        display.push(element);
                    }
                });
            }
            console.log(display);
            return display;
        }
    },
    methods: {
        getStatus(id) {
            try {
                return this.invitation.statusStatus[id][
                    ("未回答", "参加", "不参加")
                ];
            } catch (error) {
                return "未回答";
            }
        },
        setStatus(status) {
            axios.put("invitations/" + this.invitation.id + "/status", {
                status
            });
        }
    }
};
</script>

<style>
.participation_btn { width: 100px; }
</style>