<template>
    <div class="fill-height d-flex justify-center align-center">
        チャットを始めよう！
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>