<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <div class="headline ml-4">みんなの掲示板</div>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" @click="controlEditor('createItem')">投稿</v-btn>
                </v-row>
                <MTimelineEditor ref="editor"></MTimelineEditor>
                <v-divider></v-divider>
            </v-col>
            <v-col v-if="timelines.length == 0" cols="12">投稿はありません。</v-col>
            <v-col cols="12" md="6" v-for="timeline in displayTimelines" :key="timeline.id">
                <TimelineItem :timeline="timeline" :user="user"></TimelineItem>
            </v-col>
        </v-row>
        <v-pagination
            v-model="page"
            :length="pageLength"
        ></v-pagination>
    </v-container>
</template>

<script>
import { auth, watchTimelines } from "@/store";
import TimelineItem from "@/components/timelines/TimelineItem";
import MTimelineEditor from "@/components/MTimelineEditor";
export default {
    components: {
        TimelineItem,
        MTimelineEditor
    },
    data: () => ({
        page: 1,
        show_length: 10,
        user: auth.user,
        timelines: []
    }),
    async created() {
        this.timelines = await watchTimelines();
    },
    computed: {
        pageLength() {
            return Math.ceil(this.timelines.length / this.show_length);
        },
        displayTimelines() {
            return this.timelines.slice( (this.page-1) * this.show_length, this.page * this.show_length);
        }
    },
    methods: {
        controlEditor(action, ...value) {
            this.$refs.editor[action](...value);
        }
    }
};
</script>
